<template>
  <div class="standard-page">
    <!-- Show the button to go back -->
    <v-btn
      text
      class="mb-4"
      color="primary"
      @click="$router.go(-1)"
    >
      <v-icon left>
        arrow_back
      </v-icon>

      Back
    </v-btn>

    <v-card flat class="pt-8 px-md-6">
      <v-card-text class="mb-4">
        <v-row>
          <v-col cols="12" md="6">
            <!-- Show the input here -->
            <v-text-field
              v-model="formData.name"
              :error-messages="$v.formData.name.$anyError ? ['Please enter a valid name'] : null"
              @blur="$v.formData.name.$touch"
              placeholder="Myntra Winter Sale"
              label="Report Name"
              outlined
            />
          </v-col>

          <!-- Show a date range selector -->
          <v-col cols="12" md="6">
            <date-range-selector
              :max="dayjs().format('YYYY-MM-DD')"
              :min="dayjs().subtract(isAdmin ? 365 : 90, 'day').format('YYYY-MM-DD')"
              :value="dateRangeValue"
              :default-date-range="1"
              :max-date-range="isAdmin ? 365 : 90"
              @input="handleDateRangeValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <!-- Show the input for hashtags -->
            <v-combobox
              v-model="formData.hashtags"
              label="Hashtags"
              :allow-overflow="true"
              placeholder="#EndOfTheReasonSale #BigBillionDays #AmazonPrimeDay"
              multiple
              outlined
              small-chips
              deletable-chips
              hint="Mention tags related to your search, press enter to add more"
              :error-messages="$v.formData.hashtags.$anyError ? [getHashtagErrorMessage()] : null"
              @focus="didEditData = true"
              @input="handleHashtagInput"
            ></v-combobox>
          </v-col>

          <v-col cols="12" md="6">
            <!-- Show the input for hashtags -->
            <v-combobox
              v-model="formData.usernames"
              label="Usernames"
              :allow-overflow="true"
              placeholder="@flipkart @amazon @myntra"
              multiple
              outlined
              small-chips
              deletable-chips
              hint="Mention handles related to your search, press enter to add more"
              :error-messages="$v.formData.usernames.$anyError ? ['Please enter valid usernames'] : null"
              @focus="didEditData = true"
              @input="handleUsernameInput"
            ></v-combobox>
          </v-col>
        </v-row>

        <!-- Show a few radio buttons for hashtag condition -->
        <div v-if="formData.hashtags.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple hashtags, should the crawler fetch posts that contain all of them or any of them?
          </div>

          <v-radio-group
            v-model="formData.hashtagCondition"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Show a few radio buttons for username condition -->
        <div v-if="formData.usernames.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple usernames, should the crawler fetch posts that have tagged all of them or any of them?
          </div>

          <v-radio-group
            v-model="formData.usernameCondition"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Show a few radio buttons for keyword condition -->
        <div v-if="formData.keywords.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple keywords, should the crawler fetch posts that contain all of them or any of them?
          </div>

          <v-radio-group
            v-model="formData.keywordCondition"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- If the user has entered hashtag as well as username, show radio options -->
        <div v-if="formData.hashtags.length > 0 && formData.usernames.length > 0">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered both hashtags and usernames, should the crawler fetch posts that contain both of them or any of them?
          </div>

          <v-radio-group
            v-model="formData.hashtagAndUsernameCondition"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="Both of them"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>

      <v-card-actions class="pl-4 pb-8">
        <v-tooltip
          v-if="!model"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-chip
              label
              color="primary"
              v-on="on"
            >
              <v-icon
                left
                small
              >
                account_balance
              </v-icon>

              {{ nFormatter(availableModuleUsage) }} Reports
            </v-chip>
          </template>

          <span>
            You can generate {{ availableModuleUsage }} more reports
          </span>
        </v-tooltip>

        <v-spacer />

        <v-btn
          depressed
          color="primary"
          @click="validate"
          :disabled="availableModuleUsage <= 0 || isLoading || isValidating"
          :loading="isLoading || isValidating"
        >
          {{ didEditData ? "Continue" : (model ? "Update Report" : "Create Report") }}

          <v-icon right>
            arrow_forward
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength } from "vuelidate/lib/validators"

// Import child components
const AgeSelector = () => import(/* webpackChunkName: "age-selector" */ "@/blocks/common/selectors/AgeSelector.vue")
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")
const DateRangeSelector = () => import(/* webpackChunkName: "date-range-selector" */ "@/blocks/common/form/DateRangeSelector.vue")

// Export the SFC
export default {
  // Name of the component
  name: "PaidCollaborationsForm",

  // Register children components
  components: {
    AgeSelector,
    WeightSelector,
    DateRangeSelector
  },

  // Accept incoming data from parent
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    model: {
      type: Object,
      required: false,
      default: null
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is validating the form
    isValidating: false,

    // If we edited the form data
    didEditData: true,

    // The date range value
    dateRangeValue: null,

    // The visible menu dialogs
    dialog: {
      shouldShowStartDate: false,
      shouldShowEndDate: false
    },

    // The form data to be used
    formData: {
      name: "",
      startDate: "",
      endDate: "",
      hashtags: [],
      usernames: [],
      keywords: [],
      platforms: {
        instagram: true,
        tiktok: false
      },

      // The condition for multiple hashtags
      hashtagCondition: "any",
      usernameCondition: "any",
      keywordCondition: "any",

      // The condition for usernames and hashtags
      hashtagAndUsernameCondition: "any",

      // Whether or not to only fetch sponsored posts
      onlySponsoredPosts: false
    },
  }),

  // Define computable readonly variables
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("paid-collaboration")
    },

    /**
     * Whether or not the logged in user is an admin
     *
     * @returns {Boolean}
     */
    isAdmin() {
      return this.$store.getters["auth/isAdmin"]
    },

    /**
     * Get the formatted value from startDate
     *
     * @returns {String|Null}
     */
    readableStartDate() {
      return this.formData.startDate ? dayjs(this.formData.startDate).format("ll") : null
    },

    /**
     * Get the formatted value from endDate
     *
     * @returns {String|Null}
     */
     readableEndDate() {
      return this.formData.endDate ? dayjs(this.formData.endDate).format("ll") : null
    }
  },

  // Define validations for this form
  validations() {
    // Define the object
    const object = {
      formData: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(200)
        },
        hashtags: {
          maxLength: maxLength(10),
          $each: {
            regex: (v) => regex.hashtag.test(v),
          }
        },
        usernames: {
          maxLength: maxLength(10),
          $each: {
            regex: (v) => regex.username.test(v),
          }
        },
        keywords: {
          maxLength: maxLength(10),
        },
        startDate: {
          required,
          minimum: (v) => dayjs().diff(dayjs(v), 'day') <= dayjs().diff(dayjs().subtract(1, "year").format("YYYY-MM-DD"), 'day')
        },
        endDate: {
          required,
          minimum: (v) => dayjs(v).diff(this.formData.startDate, 'day') >= 1
        },
      }
    }

    // If the user has selected tiktok but hashtags is empty
    if (this.formData.platforms.tiktok === true && this.formData.hashtags.length === 0) {
      // Add the error
      object.formData.hashtags.required = required
    }

    // If the user has not entered any hashtags or usernames
    if (this.formData.hashtags.length === 0 && this.formData.usernames.length === 0) {
      // Add the error
      object.formData.hashtags.required = required
    }

    // Return the object
    return object
  },

  // Watch for any changes
  watch: {
    formData: {
      deep: true,
      handler() {
        // If we have edited the data
        if (this.didEditData === false) {
          // Update the flag
          this.didEditData = true
        }
      }
    }
  },

  // Define method functions
  methods: {
    /**
     * Copy the value from child to current state
     *
     * @param {null|Array} value
     */
    async handleDateRangeValue(value) {
      // Copy the value to local value
      this.dateRangeValue = value

      // Check if it's an array
      if (value !== null) {
        // Also update the filter values
        this.formData.startDate = value[0] || null
        this.formData.endDate = value[1] || null

        // Validate the inputs
        await this.$v.formData.startDate.$touch()
        await this.$v.formData.endDate.$touch()

        // If it is invalid
        if (this.$v.formData.startDate.$anyError || this.$v.formData.endDate.$anyError) {
          // Show a snackbar
          this.$store.dispatch("toasts/add", {
            text: "Please enter a valid date range",
          })
        }
      }
    },

    /**
     * Run the validations and if correct
     * If valid, emit to the parent
     *
     * @returns {void}
     */
    async validate() {
      // Start isValidating
      this.isValidating = true

      // If the user made some form changes
      if (this.didEditData === true) {
        // Wait for 200 milliseconds
        await wait(200)

        // Run the validations
        await this.$v.formData.$touch()

        // if it is valid
        if (!this.$v.formData.$anyError) {
          // Hide the didEdit text
          this.didEditData = false
        }

        // End the validating
        this.isValidating = false

        // End further execution
        return
      }

      // Check if the inputs are valid
      await this.$v.formData.$touch()

      // If it is invalid
      if (this.$v.formData.$anyError) {
        // Hide the validating
        this.isValidating = false

        return
      }

      // If none of the platform is selected
      if (Object.values(this.formData.platforms).filter(v => v).length === 0) {
        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Please select a social network for this report" })

        // Hide the validating
        this.isValidating = false

        // Stop further execution
        return
      }

      // Clone the formData object
      const formData = JSON.parse(JSON.stringify(this.formData))

      // Remove # from the hashtags
      formData.hashtags = formData.hashtags.map((item) => item.replaceAll("#", "").trim())

      // Remove @ from the usernames
      formData.usernames = formData.usernames.map((item) => item.replaceAll("@", "").trim())

      // Sanitize the keyword values
      formData.keywords = formData.keywords.map((item) => item.trim())

      // Otherwise, emit the event
      this.$emit("submit", formData)

      // Hide the validating
      this.isValidating = false
    },

    /**
     * Handle the hashtag input
     *
     * @returns {void}
     */
    handleHashtagInput() {
      // Go through each hashtag, and remove the # from it and then add again at the start
      this.formData.hashtags = this.formData.hashtags
        // Remove all the hash symbols
        .map((item) => item.replaceAll("#", "").trim())
        // Add the hash symbol again
        .map((item) => `#${item}`)
        // Extract the value from input
        .map((item) => {
          // Get the value
          const value = regex.hashtag.exec(item)

          // If the value is not null
          if (value !== null) {
            // Return the value
            return value[0]
          }
          // Otherwise
          else {
            // Return null
            return null
          }
        })
        // Filter out the null values
        .filter((item) => item !== null)
        // Remove the duplicates
        .filter((item, index, self) => self.indexOf(item) === index)

      // Touch the input
      this.$v.formData.hashtags.$touch()
    },

    /**
     * Handle the username input
     *
     * @returns {void}
     */
    handleUsernameInput() {
      // Go through each username, and remove the @ from it and then add again at the start
      this.formData.usernames = this.formData.usernames
        // Remove all the @ symbols
        .map((item) => item.replaceAll("@", "").trim())
        // Add the @ symbol again
        .map((item) => `@${item}`)
        // Extract the value from input
        .map((item) => {
          // Get the value
          const value = regex.username.exec(item)

          // If the value is not null
          if (value !== null) {
            // Return the value
            return value[0]
          }
          // Otherwise
          else {
            // Return null
            return null
          }
        })
        // Filter out the null values
        .filter((item) => item !== null)
        // Remove the duplicates
        .filter((item, index, self) => self.indexOf(item) === index)

      // Touch the input
      this.$v.formData.usernames.$touch()
    },

    /**
     * Get the error message for hashtag input
     *
     * @returns {String}
     */
    getHashtagErrorMessage() {
      // If the user has selected tiktok but hashtags is empty
      if (this.formData.platforms.tiktok === true && this.formData.hashtags.length === 0) {
        // Return the error message
        return "Please enter at least one hashtag"
      }

      // If the user has not entered any hashtags or usernames
      if (this.formData.hashtags.length === 0 && this.formData.usernames.length === 0) {
        // Return the error message
        return "Please enter at least one hashtag"
      }

      // If the user has entered more than 10 hashtags
      if (this.formData.hashtags.length > 10) {
        // Return the error message
        return "Please enter at most 10 hashtags"
      }

      // If the user has entered invalid hashtags
      if (this.$v.formData.hashtags.$anyError) {
        // Return the error message
        return "Please enter valid hashtags"
      }

      // Otherwise, return null
      return null
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // If we have a model data already
    if (this.model) {
      // Update the formData with these values
      this.formData = {
        name: this.model.name,

        hashtags: (this.model.hashtags || []).map((item) => `#${item}`),
        usernames: (this.model.usernames || []).map((item) => `@${item}`),
        keywords: this.model.keywords || [],

        startDate: this.model.start_date,
        endDate: this.model.end_date,

        platforms: {
          instagram: this.model.platforms.includes("instagram"),
          tiktok: this.model.platforms.includes("tiktok")
        },

        hashtagCondition: this.model.hashtag_condition,
        usernameCondition: this.model.username_condition,
        keywordCondition: this.model.keyword_condition,
        hashtagAndUsernameCondition: this.model.hashtag_username_condition,
      }

      // Also update the date range value
      this.dateRangeValue = [
        this.model.start_date,
        this.model.end_date
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
.opaque
  opacity 0.2

.contain-select-width
  min-width unset !important
  width unset !important
</style>
